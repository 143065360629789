"use client";

import {
  Itineraries,
  LoyaltyAccount,
  LoyaltyAccountsResponse,
  LoyaltyProgram,
  TravelHistoryResponse,
  UserCredits,
  UserStatus,
  UsersUserIdWelcomeActionsGetRequest,
  WelcomeActions,
  User,
  CurrencyAmount,
} from "@/generated/email_parser.openapi";
import { formatCurrency } from "@/lib/utils";
import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { filterLoyaltyAccounts } from "../loyalty-dashboard/helpers";
import { GondolaLoading } from "@/components/ui/gondolaLoading";
import {
  GmailReSyncModal,
  RatePastBookingModal,
} from "@/components/modal/welcomeActionsModal";
import {
  SearchSessionCollection,
  SearchSessionV2,
} from "@/generated/search.openapi";
import { Session } from "next-auth";

type GlobalStoreContextType = {
  user?: {
    data: User;
    status: UserStatus;
    fetchUser?: () => Promise<User | undefined>;
  };
  loyaltyData: {
    totalLoyaltyAccounts: number;
    loyaltyAccounts: LoyaltyAccount[];
    activeLoyaltyAccounts: LoyaltyAccount[];
    loyaltyPrograms: LoyaltyProgram[];
    refetchLoyaltyAccounts: () => Promise<LoyaltyAccountsResponse | undefined>;
    totalCash?: string;
    totalPointsUpdate?: string;
  };
  userCreditsData: {
    totalCashValue: number;
    totalCashValueString: string;
    userCredits?: UserCredits;
  };
  tripsData: {
    fetchItineraries: () => Promise<Itineraries | undefined>;
    itineraries: Itineraries | undefined;
  };
  travelHistoryData: {
    fetchTravelHistory: () => Promise<TravelHistoryResponse | undefined>;
    travelHistoryResponse: TravelHistoryResponse | undefined;
  };
  refetchDataPostBooking: () => void;
  welcomeActions: {
    data?: WelcomeActions;
    refetchWelcomeActions: () => Promise<WelcomeActions | undefined>;
    checkAndOpenGmailReSyncModal: () => void;
    checkAndOpenRatingsModal: () => void;
  };
  searchData: {
    recentSearchSessions: SearchSessionV2[];
    hasAlreadyFetched: boolean;
    fetchRecentSearchSession: () => Promise<SearchSessionV2[] | undefined>;
  };
  isEventPage: boolean;
};

const defaultState: GlobalStoreContextType = {
  user: undefined,
  loyaltyData: {
    totalLoyaltyAccounts: 0,
    loyaltyAccounts: [],
    activeLoyaltyAccounts: [],
    loyaltyPrograms: [],
    refetchLoyaltyAccounts: async () => undefined,
    totalCash: "",
    totalPointsUpdate: "",
  },
  userCreditsData: {
    totalCashValue: 0,
    totalCashValueString: "",
    userCredits: undefined,
  },
  travelHistoryData: {
    fetchTravelHistory: async () => undefined,
    travelHistoryResponse: undefined,
  },
  tripsData: {
    fetchItineraries: async () => undefined,
    itineraries: undefined,
  },
  refetchDataPostBooking: () => {},
  welcomeActions: {
    data: undefined,
    refetchWelcomeActions: async () => undefined,
    checkAndOpenGmailReSyncModal: () => {},
    checkAndOpenRatingsModal: () => {},
  },
  searchData: {
    recentSearchSessions: [],
    hasAlreadyFetched: false,
    fetchRecentSearchSession: async () => undefined,
  },
  isEventPage: false,
};

const GlobalStoreContext = createContext<GlobalStoreContextType>(defaultState);

export default function GlobalStoreProvider({
  children,
  session,
}: PropsWithChildren<{
  session: Session | null;
}>) {
  const [loyaltyAccounts, setLoyaltyAccounts] = useState<LoyaltyAccount[]>([]);
  const [loyaltyPrograms, setLoyaltyPrograms] = useState<LoyaltyProgram[]>([]);
  const [totalLoyaltyAccounts, setTotalLoyaltyAccounts] = useState(0);
  const [activeLoyaltyAccounts, setActiveLoyaltyAccounts] = useState<
    LoyaltyAccount[]
  >([]);
  const [loyaltyAccountsLoading, setLoyaltyAccountsLoading] = useState(true);
  const [userCredits, setUserCredits] = useState<UserCredits>();
  const [userCreditsLoading, setUserCreditsLoading] = useState(true);
  const [initialLoading, setInitialLoading] = useState(true);
  const [travelHistoryResponse, setTravelHistoryResponse] =
    useState<TravelHistoryResponse>();
  const [itineraries, setItineraries] = useState<Itineraries | undefined>();
  const [welcomeActions, setWelcomeActions] = useState<WelcomeActions>();
  const [gmailReSyncModalOpen, setGmailReSyncModalOpen] = useState(false);
  const [ratePastStayActions, setRatePastStayActions] = useState(false);
  const [recentSearchSessions, setRecentSearchSessions] = useState<
    SearchSessionV2[]
  >([]);
  const [userLoading, setUserLoading] = useState(true);
  const [initUserCall, setInitUserCall] = useState(false);
  const [userData, setUserData] = useState<User>();
  const [hasAlreadyFetched, setHasAlreadyFetched] = useState(false);
  const [totalCashValue, setTotalCashValue] = useState<CurrencyAmount>();
  const [pointsUpdateTotal, setPointsUpdateTotal] = useState<CurrencyAmount>();
  const isEventPage = Boolean(
    typeof window !== "undefined" && window.location.pathname.includes("/event")
  );

  const fetchUser = useCallback(async () => {
    try {
      const userData = (await fetch("/api/user/self").then((res) =>
        res.json()
      )) as User;
      setUserData(userData);
      return userData;
    } catch (error) {
      return;
    }
  }, []);

  const fetchUserCredits = useCallback(async () => {
    try {
      const userCredits = (await fetch("/api/user/credits").then((res) =>
        res.json()
      )) as UserCredits;
      setUserCredits(userCredits);
      return userCredits;
    } catch (error) {
      return;
    }
  }, []);

  const refetchLoyaltyAccounts = useCallback(async () => {
    try {
      const result = (await fetch(`/api/user/loyalty_accounts/v2/get`).then(
        (data) => {
          return data.json();
        }
      )) as LoyaltyAccountsResponse;
      setPointsUpdateTotal(result.pointsUpdateTotal);
      setTotalCashValue(result.totalCashValue);
      const activeAccounts = result.items?.filter(filterLoyaltyAccounts);
      setActiveLoyaltyAccounts(activeAccounts || []);
      setTotalLoyaltyAccounts(result.accountTotal || 0);
      setLoyaltyAccounts(result.items || []);
      return result;
    } catch (error) {
      return;
    }
  }, []);

  const fetchLoyaltyPrograms = useCallback(async () => {
    try {
      const result = (await fetch(`/api/loyalty_programs`).then((data) => {
        return data.json();
      })) as LoyaltyProgram[];
      setLoyaltyPrograms(result || []);
      return result;
    } catch (error) {
      return;
    }
  }, []);

  const fetchTravelHistory = useCallback(async () => {
    try {
      const result = (await fetch(`/api/user/travel_history`).then((data) =>
        data.json()
      )) as TravelHistoryResponse;
      setTravelHistoryResponse(result);
      return result;
    } catch (error) {
      return;
    }
  }, []);

  const fetchItineraries = useCallback(async () => {
    try {
      const data = (await fetch(`/api/user/trips/v2`).then((data) =>
        data.json()
      )) as Itineraries;
      setItineraries(data);
      return data;
    } catch (error) {
      return;
    }
  }, []);

  const fetchRecentSearchSession = useCallback(async () => {
    try {
      const response = (await fetch(`/api/search/hotel/recent`).then((data) =>
        data.json()
      )) as SearchSessionCollection;
      const data = response.items || [];
      setRecentSearchSessions(data);
      return data;
    } catch (error) {
      return;
    } finally {
      setHasAlreadyFetched(true);
    }
  }, []);

  const refetchDataPostBooking = () => {
    fetchUserCredits();
    fetchTravelHistory();
    fetchItineraries();
  };

  const fetchWelcomeActions = useCallback(async () => {
    try {
      if (!session?.user.id) return;
      const request: UsersUserIdWelcomeActionsGetRequest = {
        userId: session?.user.id,
      };
      const response = (await fetch("/api/user/welcome_actions", {
        method: "POST",
        body: JSON.stringify(request),
      }).then((res) => res.json())) as WelcomeActions;
      setWelcomeActions(response);
      return response;
    } catch (error) {
      return;
    }
  }, [session?.user.id]);

  const checkAndOpenGmailReSyncModal = useCallback(() => {
    const hasGmailReSyncAction = Boolean(
      welcomeActions?.emailSyncTokenExpiredWelcomeAction?.id
    );
    if (hasGmailReSyncAction) {
      setGmailReSyncModalOpen(true);
    }
  }, [welcomeActions]);

  const checkAndOpenRatingsModal = useCallback(() => {
    const hasPastRatingAction = Boolean(
      (welcomeActions?.ratePastStayWelcomeActions.items.length ?? 0) > 0
    );
    setRatePastStayActions(hasPastRatingAction);
  }, [welcomeActions]);

  const canFetchData = useMemo(() => {
    if (userLoading && Boolean(session?.user)) return "loading";
    return (
      Boolean(session?.user) &&
      (userData?.status === "EMAIL_SYNCED" ||
        (userData?.status === "SITE_ACCESS" && isEventPage))
    );
  }, [session?.user, userData?.status, userLoading, isEventPage]);

  useEffect(() => {
    if (canFetchData === "loading") return;
    if (canFetchData) {
      fetchWelcomeActions();
    }
  }, [fetchWelcomeActions, canFetchData]);

  useEffect(() => {
    if (Boolean(session?.user) && !initUserCall) {
      setInitUserCall(true);
      (async () => {
        setUserLoading(true);
        await fetchUser();
        setUserLoading(false);
      })();
    }
  }, [fetchUser, initUserCall, session]);

  useEffect(() => {
    if (canFetchData === "loading") return;
    if (canFetchData) {
      (async () => {
        setLoyaltyAccountsLoading(true);
        await refetchLoyaltyAccounts();
        await fetchLoyaltyPrograms();
        setLoyaltyAccountsLoading(false);
      })();
      (async () => {
        setUserCreditsLoading(true);
        await fetchUserCredits();
        setUserCreditsLoading(false);
      })();
    } else {
      setInitialLoading(false);
    }
  }, [
    refetchLoyaltyAccounts,
    fetchUserCredits,
    fetchLoyaltyPrograms,
    canFetchData,
  ]);

  useEffect(() => {
    if (!loyaltyAccountsLoading && !userCreditsLoading && !userLoading) {
      setInitialLoading(false);
    }
  }, [loyaltyAccountsLoading, userCreditsLoading, userLoading]);

  const totalCashValWithCurrency = useMemo(
    () =>
      formatCurrency(
        totalCashValue?.value ?? 0,
        totalCashValue?.currency ?? "USD",
        0
      ),
    [totalCashValue]
  );

  return (
    <GlobalStoreContext.Provider
      value={{
        user: userData
          ? { data: userData, status: userData.status as UserStatus, fetchUser }
          : undefined,
        loyaltyData: {
          loyaltyAccounts,
          activeLoyaltyAccounts,
          totalLoyaltyAccounts,
          loyaltyPrograms,
          refetchLoyaltyAccounts,
          totalCash: totalCashValWithCurrency,
          totalPointsUpdate: pointsUpdateTotal?.value
            ? formatCurrency(
                pointsUpdateTotal.value,
                pointsUpdateTotal.currency,
                0
              )
            : undefined,
        },
        userCreditsData: {
          totalCashValue: totalCashValue?.value ?? 0,
          totalCashValueString: totalCashValWithCurrency,
          userCredits,
        },
        travelHistoryData: {
          travelHistoryResponse,
          fetchTravelHistory,
        },
        tripsData: {
          fetchItineraries,
          itineraries,
        },
        refetchDataPostBooking,
        welcomeActions: {
          data: welcomeActions,
          refetchWelcomeActions: fetchWelcomeActions,
          checkAndOpenGmailReSyncModal,
          checkAndOpenRatingsModal,
        },
        searchData: {
          hasAlreadyFetched,
          recentSearchSessions,
          fetchRecentSearchSession,
        },
        isEventPage,
      }}
    >
      {initialLoading ? (
        <div className="h-screen w-screen place-content-center bg-background">
          <GondolaLoading />
        </div>
      ) : (
        <>
          <GmailReSyncModal
            open={gmailReSyncModalOpen}
            onOpenChange={setGmailReSyncModalOpen}
          />
          {!gmailReSyncModalOpen && (
            <RatePastBookingModal
              open={ratePastStayActions}
              onOpenChange={setRatePastStayActions}
            />
          )}
          {children}
        </>
      )}
    </GlobalStoreContext.Provider>
  );
}

export const useGlobalStore = () => {
  const {
    user,
    loyaltyData,
    userCreditsData,
    travelHistoryData,
    tripsData,
    refetchDataPostBooking,
    welcomeActions,
    searchData,
    isEventPage,
  } = useContext(GlobalStoreContext);

  return {
    user,
    loyaltyData,
    userCreditsData,
    travelHistoryData,
    tripsData,
    refetchDataPostBooking,
    welcomeActions,
    searchData,
    isEventPage,
  };
};
